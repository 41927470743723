import React, {useState} from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { 
    Box, Button, Modal,Typography, 
    Divider} from "@mui/material"; 
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import CarteService from '../../../services/CarteService';
import ComponentProduitSupermarche from './componentProduitSupermarche';

export default function CarteSupermarcheModal({dataProduitSupermarche, supermarcheId, reload}) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);

    const [numberProduitSupermarche, setNumberProduitSupermarche] = useState(1);

    const handleAddProduitSupermarche = () => {
        setNumberProduitSupermarche((prevValue) => prevValue + 1)
    }
    const handleRemoveProduitSupermarche = () => {
        if(numberProduitSupermarche > 1){
            setNumberProduitSupermarche((prevValue) => prevValue - 1)
        }
    }
    const user = useSelector((state) => state.reducerUser.user)

    //function "RegisterMenuHanlder"
    const produitSupermarcheHandler = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        let data = []
        for (let index = 0; index < numberProduitSupermarche; index++) {
            var currentProduitSupermarche = dataProduitSupermarche.filter(function (el) {
                return el.name === document.getElementById("IdProduitSupermarcheCarte-"+(index+1)).value;
            });
            // Vérifier si currentBoisson contient des éléments
            if (currentProduitSupermarche.length > 0) {
                let d = {
                    supermarcheId: supermarcheId,
                    produitSupermarcheId: currentProduitSupermarche[0].id,
                    price: document.getElementById("IdPriceProduitSupermarcheCarte-"+(index+1)).value,
                    categorie: "PRODUIT SUPERMARCHE",
                    famille: 'SUPERMARCHE',
                    created_by: user.id
                }
                data.push(d)
            } else {
                console.error("Produit Service non trouvée pour l'index", index);
            }
            
        }

        //send data to server
        await CarteService.RegisterCarte(data)
        .then((response) => {
            handleClose()
            setNumberProduitSupermarche(1)
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,        
        overflow: 'auto' 
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="secondary" endIcon={<SaveAltIcon />}>
                Creer / Modifier Menu
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Gerer la carte du supermarche
                    </Typography>
                    <br /><br />
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        
                        {/* Produit Supermarche  */}
                        <Divider variant="fullWidth" > LES PRODUITS SUPERMARCHE </Divider> 
                        <br />
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
                            <Button onClick={handleAddProduitSupermarche} variant="contained" color="secondary" endIcon={<AddCircleIcon />}>
                                Ajouter Produit
                            </Button>
                            <Button onClick={handleRemoveProduitSupermarche} variant="contained" color="error" endIcon={<DeleteIcon />}>
                                Supprimer Produit
                            </Button>
                        </div>
                        <br />
                        {[...Array(numberProduitSupermarche)].map((page,i) =>
                            <ComponentProduitSupermarche index={i} dataProduitSupermarche={dataProduitSupermarche} />
                        )}
                        
                        <br />

                        <Button disabled={isLoading} onClick={produitSupermarcheHandler}  variant="contained" sx={{float: "right", mt:2, mb:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Oui, Supprimez"}
                        </Button>
                        <br />
                        <br />
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </div>
        
    )
}
import api from "../http/http-common";

const RegisterCodePromo = (data) => {
    return api.post('/codePromo', data)
}

const GetAllCodePromo = () => {
    return api.get('/codePromo');
}

const DeleteCodePromo = (id, data) => {
    return api.delete('/codePromo/'+id, data);
}

const UpdateCodePromo = (id, data) => {
    return api.put('/codePromo/'+id, data);
}

const CodePromoService ={
    RegisterCodePromo,
    GetAllCodePromo,
    DeleteCodePromo,
    UpdateCodePromo
}

export default CodePromoService;


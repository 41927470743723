import React, { useState }  from 'react'
import "./index.css"
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../../store/user/storeUser';
import AuthService from '../../services/AuthService';

const Login = () => {

    //define state
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    //define state validation
    const [validation, setValidation] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    //function "loginHanlder"
    const loginHandler = async (e) => {
        e.preventDefault();
        
        //initialize formData
        const formData = new FormData();

        //append data to formData
        formData.append('email', email);
        formData.append('password', password);

        //send data to server
        await AuthService.Login(formData)
        .then((response) => {
            sessionStorage.setItem('token', response.data.accessToken)
            sessionStorage.setItem('refreshToken', response.data.refreshToken)
            sessionStorage.setItem('user', JSON.stringify(response.data.user))
            sessionStorage.setItem('userId', response.data.id)
            dispatch(setToken(response.data.accessToken))
            dispatch(setUser(response.data.user))
            navigate("/");
        })
        .catch((error) => {
            //assign error to state "validation"
            setValidation(error.response.data.message);
        })
    };

    return (
        <div className="Login">
            <div className="container" id="container">
                <div className="form-container sign-in-container">
                    <form onSubmit={loginHandler} >
                        <h1>Connectez-vous</h1>
                        <span>Acceder a votre tableau de bord</span>
                        <hr/>
                            {
                                validation.message && (
                                    <div className="alert alert-danger">
                                        {validation.message}
                                    </div>
                                )
                            }
                        <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                        <button type="submit" >CONNECTEZ-VOUS</button>
                    </form>
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-right">
                            <h1>Hello, BRELLIS!</h1>
                            <p>
                                En remplissant votre adresse email et votre mot de passe.
                                Vous aurez acces a toutes les donnees de BRELLIS EATS et
                                de BRELLIS HEALTH, en fonction de vos droits d'access.
                                <br />
                                <br />
                                Les donnees dont vous aurez acces seront confidentiel et manipulable avec precaution.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
import React, {useState} from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, Modal,Typography, TextField } from "@mui/material"; 
import SupplementService from '../../../services/SupplementService';

import { useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



export default function ModalRegister(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);

    const user = useSelector((state) => state.reducerUser.user)


    // State Form
    const [nameSupplement, setNameSupplement] = useState('')
    const [descriptionSupplement, setDescriptionSupplement] = useState('')
    const [imageDefaultSupplement, setImageDefaultSupplement] = useState(null)


    //function "RegisterComplementHanlder"
    const supplementHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if (!imageDefaultSupplement) {
            toast.error("Veuillez sélectionner une photo pour le supplement.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return;
        }

        let data = {
            name: nameSupplement,
            description: descriptionSupplement,
            photoGarde: imageDefaultSupplement,
            created_by: user.id
        }

        //send data to server
        await SupplementService.RegisterSupplement(data)
        .then((response) => {
            handleClose()
            setNameSupplement('')
            setDescriptionSupplement('')
            setImageDefaultSupplement(null)

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            props.reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="secondary" endIcon={<SaveAltIcon />}>
                Ajouter un supplement
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Ajouter un nouveau Supplement 
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={nameSupplement} 
                            onChange={e => setNameSupplement(e.target.value)}  
                            fullWidth 
                            label="Nom du supplement" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            type='file'
                            focused
                            onChange={(e) => setImageDefaultSupplement(e.target.files[0])}  
                            fullWidth 
                            label="Photo du supplement" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField
                            fullWidth
                            value={descriptionSupplement}
                            onChange={e => setDescriptionSupplement(e.target.value)} 
                            id="outlined-multiline-static"
                            label="Description du supplement"
                            multiline
                            rows={4}
                            color="secondary"
                            sx={{ mt: 2 }}
                        />
                        <Button disabled={isLoading} onClick={supplementHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </div>
        
    )
}


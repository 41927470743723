import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LogoutIcon from '@mui/icons-material/Logout';
// import { useNavigate } from "react-router-dom";
import { resetToken, resetUser } from '../../store/user/storeUser';
import { useDispatch } from 'react-redux';


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const navigate = useNavigate();
  const dispatch = useDispatch()

  //function "loginHanlder"
  const logoutHandler = async (e) => {
    e.preventDefault();
    sessionStorage.clear()
    dispatch(resetToken())
    dispatch(resetUser())
    // navigate("/login");
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
      </Box>

      {/* ICONS */}
      <Box display="flex">
        
        <IconButton onClick={logoutHandler}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;

import React, {useState} from 'react';
import { Box, Button, Modal,Typography, TextField, IconButton } from "@mui/material"; 
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

import { useSelector } from 'react-redux'

import PlatService from '../../../services/PlatService';

export default function ModalUpdate(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);


    const user = useSelector((state) => state.reducerUser.user)

    // State Form
    const [namePlat, setNamePlat] = useState(props.data.row.name)
    const [descriptionPlat, setDescriptionPlat] = useState(props.data.row.description)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    //function "UpdatePlatHanlder"
    const platHandler = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        let data = {
            name: namePlat,
            description: descriptionPlat,
            updated_by: user.id
        }
        const id = props.data.row.id
        //send data to server
        await PlatService.UpdatePlat(id,data)
        .then((response) => {
            handleClose()

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            handleClose()
            console.log('err', error)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };

    return (
        <div>
            <IconButton aria-label="update" size="large" onClick={handleOpen}  color="warning">
                <AppRegistrationIcon fontSize="inherit" />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Modifier un plat
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={namePlat} 
                            onChange={e => setNamePlat(e.target.value)}  
                            fullWidth 
                            label="Nom du Plat" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField
                            fullWidth
                            value={descriptionPlat}
                            onChange={e => setDescriptionPlat(e.target.value)} 
                            id="outlined-multiline-static"
                            label="Description du Plat"
                            multiline
                            rows={4}
                            color="secondary"
                            sx={{ mt: 2 }}
                        />
                        <Button disabled={isLoading} onClick={platHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


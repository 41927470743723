import api from "../http/http-common";

const Login = (data) => {
    return api.post('/auth/signin', data)
}

const refresh = (data) => {
    return api.post('/auth/refreshtoken', data);
}
const AuthService ={
    Login,
    refresh
}

export default AuthService;
import React, {useState} from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { 
    Box, Button, Modal,Typography, 
    Divider} from "@mui/material"; 
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import CarteService from '../../../services/CarteService';
import ComponentPlatComplement from './ComponentPlatComplement';
import ComponentBoisson from './ComponentBoisson';
import ComponentSupplement from './ComponentSupplement';

export default function MenuModal({dataBoisson, dataPlat, dataComplement, dataSupplement, restaurantId, reload}) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);

    const [numberPlatComplement, setNumberPlatComplement] = useState(1);
    const [numberBoisson, setNumberBoisson] = useState(1);
    const [numberSupplement, setNumberSupplement] = useState(1);
    
    const handleAddPlatComplement = () => {
        setNumberPlatComplement((prevValue) => prevValue + 1)
    }
    const handleRemovePlatComplement = () => {
        if(numberPlatComplement > 1){
            setNumberPlatComplement((prevValue) => prevValue - 1)
        }
    }

    const handleAddBoisson = () => {
        setNumberBoisson((prevValue) => prevValue + 1)
    }
    const handleRemoveBoisson = () => {
        if(numberBoisson > 1){
            setNumberBoisson((prevValue) => prevValue - 1)
        }
    }

    const handleAddSupplement = () => {
        setNumberSupplement((prevValue) => prevValue + 1)
    }
    const handleRemoveSupplement = () => {
        if(numberSupplement > 1){
            setNumberSupplement((prevValue) => prevValue - 1)
        }
    }

    const user = useSelector((state) => state.reducerUser.user)

    //function "RegisterMenuHanlder"
    const menuHandler = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        let data = []
        for (let index = 0; index < numberPlatComplement; index++) {
            var currentPlat = dataPlat.filter(function (el) {
                return el.name === document.getElementById("IdPlatMenu-"+(index+1)).value;
            });
            var currentComplement = dataComplement.filter(function (el) {
                return el.name === document.getElementById("IdComplementMenu-"+(index+1)).value;
            });

            // Vérifier si currentBoisson contient des éléments
            if ( (currentPlat.length > 0) ) {
                if ( (currentComplement.length > 0) ) {
                    let d = {
                        restaurantId: restaurantId,
                        platId: currentPlat[0].id,
                        complementId: currentComplement[0].id,
                        price: document.getElementById("IdPricePlatComplementMenu-"+(index+1)).value,
                        categorie: "PLAT",
                        famille: 'RESTAURANT',
                        created_by: user.id
                    }
                    data.push(d)
                }else{
                    let d = {
                        restaurantId: restaurantId,
                        platId: currentPlat[0].id,
                        price: document.getElementById("IdPricePlatComplementMenu-"+(index+1)).value,
                        categorie: "PLAT",
                        famille: 'RESTAURANT',
                        created_by: user.id
                    }
                    data.push(d)
                }                
            } else {
                console.error("Plat non trouvée pour l'index", index);
                // Vous pouvez également gérer cette situation d'une autre manière, selon vos besoins
            }

            
        }

        for (let index = 0; index < numberBoisson; index++) {
            var currentBoisson = dataBoisson.filter(function (el) {
                return el.name === document.getElementById("IdBoissonMenu-"+(index+1)).value;
            });
            // Vérifier si currentBoisson contient des éléments
            if (currentBoisson.length > 0) {
                let d = {
                    restaurantId: restaurantId,
                    boissonId: currentBoisson[0].id,
                    price: document.getElementById("IdPriceBoissonMenu-"+(index+1)).value,
                    categorie: "BOISSON",
                    famille: 'RESTAURANT',
                    created_by: user.id
                }
                data.push(d)
            } else {
                console.error("Boisson non trouvée pour l'index", index);
                // Vous pouvez également gérer cette situation d'une autre manière, selon vos besoins
            }
            
        }

        for (let index = 0; index < numberSupplement; index++) {
            var currentSupplement = dataSupplement.filter(function (el) {
                return el.name === document.getElementById("IdSupplementMenu-"+(index+1)).value;
            });

            // Vérifier si currentBoisson contient des éléments
            if (currentSupplement.length > 0) {
                let d = {
                    restaurantId: restaurantId,
                    supplementId: currentSupplement[0].id,
                    price: document.getElementById("IdPriceSupplementMenu-"+(index+1)).value,
                    categorie: "SUPPLEMENT",
                    famille: 'RESTAURANT',
                    created_by: user.id
                }
                data.push(d)
            } else {
                console.error("Supplement non trouvée pour l'index", index);
                // Vous pouvez également gérer cette situation d'une autre manière, selon vos besoins
            }
            
        }

        //send data to server
        await CarteService.RegisterCarte(data)
        .then((response) => {
            handleClose()

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,        
        overflow: 'auto' 
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="secondary" endIcon={<SaveAltIcon />}>
                Creer / Modifier Menu
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Gerer le Menu
                    </Typography>
                    <br /><br />
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        {/* Plat & COmplement */}
                        <Divider variant="fullWidth" > LES PLATS AVEC COMPLEMENT </Divider> 
                        <br />
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
                            <Button onClick={handleAddPlatComplement} variant="contained" color="secondary" endIcon={<AddCircleIcon />}>
                                Ajouter Plat
                            </Button>
                            <Button onClick={handleRemovePlatComplement} variant="contained" color="error" endIcon={<DeleteIcon />}>
                                Supprimer Plat
                            </Button>
                        </div>
                        <br />
                        {[...Array(numberPlatComplement)].map((number,i) =>
                            <ComponentPlatComplement index={i} dataPlat={dataPlat} dataComplement={dataComplement} />
                        )}
                        <br />

                        {/* Boissons  */}
                        <Divider variant="fullWidth" > LES BOISSONS </Divider> 
                        <br />
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
                            <Button onClick={handleAddBoisson} variant="contained" color="secondary" endIcon={<AddCircleIcon />}>
                                Ajouter Boisson
                            </Button>
                            <Button onClick={handleRemoveBoisson} variant="contained" color="error" endIcon={<DeleteIcon />}>
                                Supprimer Boisson
                            </Button>
                        </div>
                        <br />
                        {[...Array(numberBoisson)].map((page,i) =>
                            <ComponentBoisson index={i} dataBoisson={dataBoisson} />
                        )}
                        
                        <br />

                        {/* Supplement */}
                        <Divider variant="fullWidth" > LES SUPPLEMENTS </Divider> 
                        <br />
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
                            <Button onClick={handleAddSupplement} variant="contained" color="secondary" endIcon={<AddCircleIcon />}>
                                Ajouter Supplement
                            </Button>
                            <Button onClick={handleRemoveSupplement} variant="contained" color="error" endIcon={<DeleteIcon />}>
                                Supprimer Supplement
                            </Button>
                        </div>
                        <br />
                        {[...Array(numberSupplement)].map((page,i) =>
                            <ComponentSupplement index={i} dataSupplement={dataSupplement} />
                        )}
                        <br />
                        <Button disabled={isLoading} onClick={menuHandler}  variant="contained" sx={{float: "right", mt:2, mb:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Oui, Supprimez"}
                        </Button>
                        <br />
                        <br />
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </div>
        
    )
}
import React, {useState} from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, Modal,Typography, TextField } from "@mui/material"; 
import UserService from '../../../services/UserService';

import { useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



export default function ModalRegister(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const user = useSelector((state) => state.reducerUser.user)


    // State Form
    const [nameUser, setNameUser] = useState('')
    const [emailUser, setEmailUser] = useState('')
    const [phoneUser, setPhoneUser] = useState('')
    const [password, setPassword] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [imageProfil, setImageProfil] = useState([])

    const userHandler = async (e) => {
        e.preventDefault();

        let data = {
            name: nameUser,
            email: emailUser,
            phone: phoneUser,
            password: password,
            photoGarde: imageProfil,
            birthdate: birthdate,
            created_by: user.id
        }

        //send data to server
        await UserService.RegisterUser(data)
        .then((response) => {
            handleClose()
            setNameUser('')
            setEmailUser('')
            setPhoneUser('')
            setPassword('')
            setBirthdate('')
            setImageProfil()

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props.reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="secondary" endIcon={<SaveAltIcon />}>
                Ajouter un utilisateur
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Ajouter une nouvelle boisson  
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={nameUser} 
                            onChange={e => setNameUser(e.target.value)}  
                            fullWidth 
                            label="Nom utilisateur" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={emailUser} 
                            onChange={e => setEmailUser(e.target.value)}  
                            fullWidth 
                            label="Email utilisateur" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={phoneUser} 
                            onChange={e => setPhoneUser(e.target.value)}  
                            fullWidth 
                            label="Telephone utilisateur" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            type='password'
                            value={password} 
                            onChange={e => setPassword(e.target.value)}  
                            fullWidth 
                            label="Mot de passe utilisateur" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            type='date'
                            focused
                            value={birthdate} 
                            onChange={e => setBirthdate(e.target.value)}  
                            fullWidth 
                            label="Date Naissance utilisateur" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            type='file'
                            focused
                            onChange={(e) => setImageProfil(e.target.files[0])}  
                            fullWidth 
                            label="Photo de profil" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        
                        <Button onClick={userHandler} variant="contained" sx={{float: "right", mt:2}}>Validez ici</Button>

                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </div>
        
    )
}


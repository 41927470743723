import React, {useState} from 'react'
import { 
    Box, TextField, Autocomplete, Grid
} from "@mui/material";


const ComponentSupplement = ({index, dataSupplement}) => {
    const [priceSupplement, setPriceSupplement] = useState()

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Autocomplete
                        disablePortal
                        id={`IdSupplementMenu-${index+1}`}
                        options={dataSupplement}
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%', color: 'white' }}
                        renderInput={(params) => <TextField label="SUPPLEMENT" placeholder="Choisir un supplement" color='secondary'  {...params} />}
                    />
                </Grid>
                <Grid item xs={2} >
                    <TextField 
                        id={`IdPriceSupplementMenu-${index+1}`}
                        type='number'
                        value={priceSupplement} 
                        onChange={e => setPriceSupplement(e.target.value)}  
                        fullWidth 
                        label="Prix du Supplement" 
                        color="secondary"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ComponentSupplement;

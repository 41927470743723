import React, {useState} from 'react'
import { 
    Box, TextField, Autocomplete, Grid
} from "@mui/material";

const ComponentPlatComplement = ({index, dataPlat, dataComplement}) => {
    const [pricePlatComplement, setPricePlatComplement] = useState()
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id={`IdPlatMenu-${index+1}`}
                        options={dataPlat}
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%', color: 'white' }}
                        renderInput={(params) => <TextField label="PLAT" placeholder="Choisir un plat" color='secondary'  {...params} />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id={`IdComplementMenu-${index+1}`}
                        options={dataComplement}
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%', color: 'white' }}
                        renderInput={(params) => <TextField label="COMPLEMENT" placeholder="Choisir un Complement" color='secondary'  {...params} />}
                    />
                </Grid>
                <Grid item xs={2} >
                    <TextField
                        id={`IdPricePlatComplementMenu-${index+1}`} 
                        type='number'
                        value={pricePlatComplement} 
                        onChange={e => setPricePlatComplement(e.target.value)}  
                        fullWidth 
                        label="Prix du Plat" 
                        color="secondary"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ComponentPlatComplement;

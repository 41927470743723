import { useEffect, useState } from 'react';

import { Box, Switch  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import ModalRegister from "./component/ModalRegister";
import ModalUpdate from "./component/ModalUpdate";
import ModalDelete from "./component/ModalDelete";
import { toast } from 'react-toastify';

import CodePromoService from '../../services/CodePromoService';
import { useSelector } from 'react-redux'

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const CodePromoPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const user = useSelector((state) => state.reducerUser.user)

  const reload = (res) => {
    if(res === true){
      codePromoHandler()
    }
  }

  const columns = [
    {
      field: "code",
      headerName: "Code Promo",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
        field: "pourcentage",
        headerName: "Pourcentage Promo",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "sommeMin",
        headerName: "Somme minimum Promo",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "startDate",
        headerName: "Date debut Promo",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "endDate",
        headerName: "Date fin Promo",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
      field: "Action(s)",
      align: "right",
      headerAlign: "right",
      // headerName: "Action(s)",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <Switch
            checked={cellValues.row.active === true ? true : false}
            onChange={() => handleChange(cellValues)}  
            {...label}  color="default"  />
            <ModalUpdate data={cellValues} reload={reload}/>
            <ModalDelete data={cellValues} reload={reload} />
          </div>
          
        );
      }
    },
  ];

  const [dataCodePromo, setDataCodePromo] = useState([])
  //function "loginHanlder"
  const codePromoHandler = async () => {
    //send data to server
    await CodePromoService.GetAllCodePromo()
    .then((response) => {
      setDataCodePromo(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const handleChange = async (data) => {
    let dataF = {
        active: !data.row.active,
        updated_by: user.id
    }
    const id = data.row.id
    await CodePromoService.UpdateCodePromo(id, dataF)
    .then((response) => {
        toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        codePromoHandler()
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };


  useEffect(() => {
    //Runs on every render
    codePromoHandler()
  }, []);

  return (
    <Box m="20px">
      <Header
        title="CODE PROMO"
        subtitle="Liste de tous les code promo"
      />

      <ModalRegister reload={reload} />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dataCodePromo}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default CodePromoPage;

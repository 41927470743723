import api from "../http/http-common";

const RegisterSupermarche = (data) => {
    return api.post('/supermarche', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllSupermarche = () => {
    return api.get('/supermarche');
}

const DeleteSupermarche = (id, data) => {
    return api.delete('/supermarche/'+id, data);
}

const UpdateSupermarche = (id, data) => {
    return api.put('/supermarche/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const SupermarcheService ={
    RegisterSupermarche,
    GetAllSupermarche,
    DeleteSupermarche,
    UpdateSupermarche
}

export default SupermarcheService;


import api from "../http/http-common";

const RegisterRole = (data) => {
    return api.post('/roles', data)
}

const GetAllRole = () => {
    return api.get('/roles');
}

const DeleteRole = (id, data) => {
    return api.delete('/roles/'+id, data);
}

const UpdateRole = (id, data) => {
    return api.put('/roles/'+id, data);
}

const RoleService ={
    RegisterRole,
    GetAllRole,
    DeleteRole,
    UpdateRole
}

export default RoleService;


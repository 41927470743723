import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography,
  List,Grid,
  } from "@mui/material";
import { toast } from 'react-toastify';
import Header from "../../../components/Header";
import { useParams } from "react-router-dom";

import CarteService from '../../../services/CarteService';
import PlatService from '../../../services/PlatService';
import SupplementService from '../../../services/SupplementService';
import BoissonService from '../../../services/BoissonService';
import ComplementService from '../../../services/ComplementService';

import MenuModal from '../component/MenuModal';
import ComponentListPlatComplement from '../component/ComponentListPlatComplement';
import ComponentListBoisson from '../component/ComponentListBoisson';
import ComponentListSupplement from '../component/ComponentListSupplement';


const SectionRestaurant = () => {
  const routeParams = useParams();
  const reload = (res) => {
    if(res === true){
      console.log('2')
      menuHandler()
    }
  }

  const [data, setData] = useState([])
  const menuHandler = async () => {
    let mode = 'RESTAURANT'
    //send data to server
    await CarteService.GetAllCarte(routeParams.id, mode)
    .then((response) => {
      setData(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const [dataSupplement, setDataSupplement] = useState([])

  const supplementHandler = async () => {
    //send data to server
    await SupplementService.GetAllSupplement()
    .then((response) => {
      setDataSupplement(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };
  const [dataPlat, setDataPlat] = useState([])
  //function "loginHanlder"
  const platHandler = async () => {
    //send data to server
    await PlatService.GetAllPlat()
    .then((response) => {
      setDataPlat(response.data.data)
    })
    .catch((error) => {
      console.log('s', error)

      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const [dataBoisson, setDataBoisson] = useState([])

  const boissonHandler = async () => {
    //send data to server
    await BoissonService.GetAllBoisson()
    .then((response) => {
      setDataBoisson(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const [dataComplement, setDataComplement] = useState([])

  const complementHandler = async () => {
    //send data to server
    await ComplementService.GetAllComplement()
    .then((response) => {
      setDataComplement(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };


  useEffect(() => {
    menuHandler()
    supplementHandler()
    platHandler()
    boissonHandler()
    complementHandler()
  }, []);
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  return (
    <Box  m="20px">
      <Header 
        title="MENUS"
        subtitle="Liste de tous les repas, boissons et complements par restaurant"
      />
      <div style={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
        <MenuModal dataBoisson={dataBoisson} dataComplement={dataComplement} dataPlat={dataPlat} dataSupplement={dataSupplement} restaurantId={routeParams.id} reload={reload} />
      </div>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h2" component="div">
          Tous les Plat(s) + Complement(s) de : <span>{(data.length > 0)? data[0].restaurant.name: ''}</span>
        </Typography>
        <Demo>
          <List >
            {data.map((value,i) =>
              <ComponentListPlatComplement index={i} reload={reload} value={value}/>
            )}
          </List>
        </Demo>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h2" component="div">
          Toute(s) Boisson(s) de : <span>{(data.length > 0)? data[0].restaurant.name: ''}</span>
        </Typography>
        <Demo>
          <List >
            {data.map((value,i) =>
              <ComponentListBoisson reload={reload} value={value}/>
            )}
          </List>
        </Demo>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h2" component="div">
          Toute(s) Supplement(s) de : <span>{(data.length > 0)? data[0].restaurant.name: ''}</span>
        </Typography>
        <Demo>
          <List >
            {data.map((value,i) =>
              <ComponentListSupplement reload={reload} value={value}/>
            )}
          </List>
        </Demo>
      </Grid>
    </Box>
  );
};

export default SectionRestaurant;

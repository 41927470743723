import React, {useState} from 'react'
import { 
    Box, TextField, Autocomplete, Grid
} from "@mui/material";


const ComponentBoisson = ({index, dataBoisson}) => {
    const [priceBoissonMenu, setPriceBoissonMenu] = useState()

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Autocomplete
                        disablePortal
                        id={`IdBoissonMenu-${index+1}`}
                        options={dataBoisson}
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%', color: 'white' }}
                        renderInput={(params) => <TextField label="BOISSON" placeholder="Choisir une Boisson" color='secondary'  {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        id={`IdPriceBoissonMenu-${index+1}`}
                        type='number'
                        value={priceBoissonMenu} 
                        onChange={e => setPriceBoissonMenu(e.target.value)}  
                        fullWidth 
                        label="Prix de la Boisson" 
                        color="secondary"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ComponentBoisson;

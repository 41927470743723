import api from "../http/http-common";

const ManagementUser = (data) => {
    return api.post('/managementUsers', data)
}

const ManagementUserService = {
    ManagementUser
}

export default ManagementUserService;
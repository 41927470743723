import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';

export default function ListDataRole(props) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {props.dataAssign.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        return (
          <ListItem
            key={value.id}
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText id={labelId} primary={value.libelle} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import StorefrontIcon from '@mui/icons-material/Storefront';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import TapasIcon from '@mui/icons-material/Tapas';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h4" color={colors.grey[100]}>
                  BRELLIS HOLDING
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* PART BRELLIS EATS */}
            <Typography
              variant="h4"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              BRELLIS-EATS
            </Typography>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              RESTAURATION
            </Typography>
            <Item
              title="Restaurant(s)"
              to="/restaurants"
              icon={<StorefrontIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Plat(s)"
              to="/plats"
              icon={<LunchDiningIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Complement(s)"
              to="/complements"
              icon={<DinnerDiningIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Boisson(s)"
              to="/boissons"
              icon={<SportsBarIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Supplement(s)"
              to="/supplements"
              icon={<TapasIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              SUPERMARCHE
            </Typography>
            <Item
              title="Supermarche(s)"
              to="/supermarches"
              icon={<LocalGroceryStoreIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Produit(s) Supermarche"
              to="/produitSupermarche"
              icon={<ProductionQuantityLimitsIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              COMMANDE
            </Typography>
            <Item
              title="Code promo"
              to="/codePromo"
              icon={<LocalActivityIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Commande(s)"
              to="/order"
              icon={<ShoppingCartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           

            {/* END PART BRELLIS EATS */}
            
            {/* Part Gestion Users */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              GESTION UTILISATEUR
            </Typography>
            <Item
              title="Gestion d'utilisateur(s)"
              to="/gestionUsers"
              icon={<ManageAccountsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Utilisateur(s)"
              to="/users"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Role(s)"
              to="/roles"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* End Part Gestion Users */}

            {/* PART - ADMINISTRATION */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              ADMINISTRATION
            </Typography>
            <Item
              title="Permission(s)"
              to="/permissions"
              icon={<WorkspacePremiumIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Ville(s)"
              to="/ville"
              icon={<LocationCityIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import api from "../http/http-common";

const RegisterBoisson = (data) => {
    return api.post('/boisson', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllBoisson = () => {
    return api.get('/boisson');
}

const DeleteBoisson = (id, data) => {
    return api.delete('/boisson/'+id, data);
}

const UpdateBoisson = (id, data) => {
    return api.put('/boisson/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const BoissonService ={
    RegisterBoisson,
    GetAllBoisson,
    DeleteBoisson,
    UpdateBoisson
}

export default BoissonService;


import { useEffect, useState } from 'react';
import { 
  Box, Autocomplete, TextField, 
  Accordion, AccordionSummary, AccordionDetails,
  Typography,  Tabs, Tab 
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import Header from "../../components/Header";

import ListDataRole from './component/list/ListDataRole';
import ListDataPermission from './component/list/ListDataPermission';
import CustomTabPanel from "./component/CustomTabPanel";
import a11yProps from "./component/allyProps";
import ModalValidate from "./component/modal/ModalValidate";
import ModalValidatePermission from "./component/modal/ModalValidatePermission";

import UserService from '../../services/UserService';
import PermissionService from '../../services/PermissionService';
import RoleService from '../../services/RoleService';

const UserPage = () => {
  const [userChoose, setUserChoose] = useState(null)
  const [roleChoose, setRoleChoose] = useState(null)
  const [roleAssign, setRoleAssign] = useState([])
  const [permissionAssign, setPermissionAssign] = useState([])
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const reload = (res) => {
    if(res === true){
      userHandler()
    }
  }

  const [dataUser, setDataUser] = useState([])
  const userHandler = async () => {
    //send data to server
    await UserService.GetAllUser()
    .then((response) => {
      setDataUser(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };
  const [dataPermission, setDataPermission] = useState([])
  const permissionHandler = async () => {
    //send data to server
    await PermissionService.GetAllPermission()
    .then((response) => {
      setDataPermission(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };
  const [dataRole, setDataRole] = useState([])
  const roleHandler = async () => {
    //send data to server
    await RoleService.GetAllRole()
    .then((response) => {
      setDataRole(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };
  const searchUserChoose = (user) => {
    setUserChoose(user);
    setRoleAssign(user.roles)
  }
  const searchRoleChoose = (role) => {
    setRoleChoose(role);
    setPermissionAssign(role.permissions)
  }

  useEffect(() => {
    userHandler()
    permissionHandler()
    roleHandler()
  }, []);
  
  let propsRoleModalValidate = {
    data: dataRole,
    userChoose: userChoose,
  }
  let propsPermissionModalValidate = {
    data: dataPermission,
    roleChoose: roleChoose,
  }
  let propsListData = {
    dataAssign: roleAssign,
  }
  let propsListDataPermission = {
    dataAssign: permissionAssign,
  }
  return (
    <Box m="20px">
      <Header
        title="GESTION DES UTILISATEURS"
        subtitle="Liste de toutes les permissions et roles "
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} 
        textColor="secondary"
        indicatorColor="secondary"
        onChange={handleChange} aria-label="basic tabs example">
          <Tab label="ROlE(S) - UTILISATEUR" {...a11yProps(0)} />
          <Tab label="PERMISSION(S) - ROLE" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'start', marginTop:'2%'}}>
          <Autocomplete
            disablePortal
            onChange={(event, newValue) => {
              searchUserChoose(newValue);
            }}
            id="combo-box-demo"
            getOptionLabel={(option) => option.name}
            options={dataUser}
            sx={{ width: '90%' }}
            renderInput={(params) => <TextField color="secondary" {...params} label="UTILISATEUR" />}
          />
          <ModalValidate {...propsRoleModalValidate} reload={reload} / >
        </div>
        <Accordion expanded={true} sx={{height: '100%'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>ROLES(S) -&gt; UTILISATEUR</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ListDataRole {...propsListData } />
          </AccordionDetails>
        </Accordion>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'start', marginTop:'2%'}}>
          <Autocomplete
            onChange={(event, newValue) => {
              searchRoleChoose(newValue);
            }}
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(option) => option.libelle}
            options={dataRole}
            sx={{ width: '90%' }}
            renderInput={(params) => <TextField color="secondary" {...params} label="ROLE" />}
          />
          <ModalValidatePermission {...propsPermissionModalValidate} reload={reload} / >
        </div>
        
        <Accordion expanded={true} sx={{height: '100%'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>PERMISSION(S) -&gt; ROLE</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ListDataPermission {...propsListDataPermission }/>
          </AccordionDetails>
        </Accordion>
      </CustomTabPanel>
    </Box>
  );
};

export default UserPage;

import api from "../http/http-common";

const RegisterUser = (data) => {
    return api.post('/user', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllUser = () => {
    return api.get('/user');
}

const DeleteUser = (id, data) => {
    return api.delete('/user/'+id, data);
}

const UpdateUser = (id, data) => {
    return api.put('/user/'+id, data);
}

const UserService ={
    RegisterUser,
    GetAllUser,
    DeleteUser,
    UpdateUser
}

export default UserService;


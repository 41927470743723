import { createSlice } from '@reduxjs/toolkit'

export const userStore = createSlice({
    name: 'storeUser',
    initialState: {
        token: null,
        user: null,
    },
    reducers: {
        setUser: (state, action) =>{
            state.user = action.payload
        },
        setToken: (state, action) =>{
            state.token = action.payload
        },
        resetToken: (state) => {
            state.token = null
        },
        resetUser: (state) => {
            state.user = null
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUser, setToken, resetToken, resetUser } = userStore.actions

export default userStore.reducer
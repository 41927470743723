import { useEffect, useState } from 'react';

import { Box, useTheme, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import MenuBookIcon from '@mui/icons-material/MenuBook';

import ModalRegister from "./component/ModalRegister";
import ModalUpdate from "./component/ModalUpdate";
import ModalDelete from "./component/ModalDelete";
import ModalFile from './component/ModalFile';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import RestaurantService from '../../services/RestaurantService';
import VilleService from '../../services/VilleService';


const RestaurantPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const reload = (res) => {
    if(res === true){
      restaurantHandler()
    }
  }
  const goTOMenu = (id) => {
    navigate('/carte/'+id+'/restaurant')
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "E-mail",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Numero Telephone",
      flex: 1,
    },
    {
      field: "geolocalisationName",
      headerName: "Localisation",
      flex: 1,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "Action(s)",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <IconButton aria-label="menu" size="large" onClick={() => goTOMenu(cellValues.row.id)} color="secondary">
              <MenuBookIcon fontSize="inherit" />
            </IconButton>
            <ModalFile data={cellValues} reload={reload} />
            <ModalUpdate data={cellValues} reload={reload} />
            <ModalDelete data={cellValues} reload={reload} />
          </div>
          
        );
      }
    },
  ];

  const [dataRestaurant, setDataRestaurant] = useState([])
  const [dataVille, setDataVille] = useState([])

  const restaurantHandler = async () => {
    //send data to server
    await RestaurantService.GetAllRestaurant()
    .then((response) => {
      setDataRestaurant(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const villeHandler = async () => {
    //send data to server
    await VilleService.GetAllVille()
    .then((response) => {
      setDataVille(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      console.log('error', error)
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  useEffect(() => {
    //Runs on every render
    restaurantHandler()
    villeHandler()
  }, []);

  return (
    <Box m="20px">
      <Header
        title="RESTAURANTS"
        subtitle="Liste de tous les restaurants"
      />

      <ModalRegister reload={reload} dataVille={dataVille} />
      
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dataRestaurant}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default RestaurantPage;

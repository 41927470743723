import React, {useState} from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, IconButton, Button, Modal,Typography, TextField } from "@mui/material"; 
import SupermarcheService from '../../../services/SupermarcheService';

import { useSelector } from 'react-redux'

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



export default function ModalFile(props) {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const user = useSelector((state) => state.reducerUser.user)
    // State Form
    const [nameSupermarche, setNameSupermarche] = useState(props.data.row.name)
    const [imageGardeSupermarche, setImageGardeSupermarche] = useState(null)

    //function "RegisterRestaurantHanlder"
    const supermarcheHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let data = {
            name: nameSupermarche,
            photoGarde: imageGardeSupermarche,
            updated_by: user.id
        }
        const id = props.data.row.id
        //send data to server
        await SupermarcheService.UpdateSupermarche(id,data)
        .then((response) => {
            handleClose()
            setImageGardeSupermarche(null)

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <IconButton aria-label="updateFile" size="large" onClick={handleOpen} color="info">
                <FileCopyIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Modifier un photo de garde
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            type='file'
                            focused
                            onChange={(e) => setImageGardeSupermarche(e.target.files[0])}  
                            fullWidth 
                            label="Photo de garde du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <Button disabled={isLoading} onClick={supermarcheHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}


import React, {useState} from 'react'
import { 
    Box, TextField, Autocomplete, Grid
} from "@mui/material";


const ComponentProduitSupermarche = ({index, dataProduitSupermarche}) => {
    const [priceProduitSupermarcheCarte, setPriceProduitSupermarcheCarte] = useState()

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Autocomplete
                        disablePortal
                        id={`IdProduitSupermarcheCarte-${index+1}`}
                        options={dataProduitSupermarche}
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%', color: 'white' }}
                        renderInput={(params) => <TextField label="PRODUIT SUPERMARCHE" placeholder="Choisir un produit du supermarche" color='secondary'  {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        id={`IdPriceProduitSupermarcheCarte-${index+1}`}
                        type='number'
                        value={priceProduitSupermarcheCarte} 
                        onChange={e => setPriceProduitSupermarcheCarte(e.target.value)}  
                        fullWidth 
                        label="Prix du produit supermarche" 
                        color="secondary"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ComponentProduitSupermarche;

import api from "../http/http-common";

const RegisterPlat = (data) => {
    return api.post('/plat', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllPlat = () => {
    return api.get('/plat');
}

const DeletePlat = (id, data) => {
    return api.delete('/plat/'+id, data);
}

const UpdatePlat = (id, data) => {
    return api.put('/plat/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const PlatService ={
    RegisterPlat,
    GetAllPlat,
    DeletePlat,
    UpdatePlat
}

export default PlatService;


import { useEffect, useState } from 'react';

import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import ModalRegister from "./component/ModalRegister";
import ModalUpdate from "./component/ModalUpdate";
import ModalDelete from "./component/ModalDelete";
import { toast } from 'react-toastify';

import ProduitSupermarcheService from '../../services/ProduitSupermarcheService';


const ProduitSupermarchePage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const reload = (res) => {
    if(res === true){
      produitSupermarcheHandler()
    }
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Action(s)",
      align: "right",
      headerAlign: "right",
      // headerName: "Action(s)",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <ModalUpdate data={cellValues} reload={reload}/>
            <ModalDelete data={cellValues} reload={reload} />
          </div>
          
        );
      }
    },
  ];

 

  const [dataProduitSupermarche, setDataProduitSupermarche] = useState([])
  //function "loginHanlder"
  const produitSupermarcheHandler = async () => {
    //send data to server
    await ProduitSupermarcheService.GetAllProduitSupermarche()
    .then((response) => {
      setDataProduitSupermarche(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  useEffect(() => {
    //Runs on every render
    produitSupermarcheHandler()
  }, []);

  return (
    <Box m="20px">
      <Header
        title="PRODUITS SUPERMARCHE"
        subtitle="Liste de tous les Produits supermarche"
      />

      <ModalRegister reload={reload} />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dataProduitSupermarche}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default ProduitSupermarchePage;

import React from 'react'
import { useParams } from "react-router-dom";
import SectionRestaurant from './section/SectionRestaurant';
import SectionSupermarche from './section/SectionSupermarche';

function Item() {
  const routeParams = useParams();
  if (routeParams.mode === 'restaurant') {
    return (
      <SectionRestaurant />
    );
  }else if (routeParams.mode === 'supermarche'){
    return (
      <SectionSupermarche />
    );
  }
  
}

export default function CartePage() {
  return (
    <div>
      <Item />
    </div>
  );
}

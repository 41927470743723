import React, {useState} from 'react';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Box, Button, Modal,Typography, TextField, IconButton } from "@mui/material"; 

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

import { useSelector } from 'react-redux'

import SupermarcheService from '../../../services/SupermarcheService';



export default function ModalUpdate(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);


    const user = useSelector((state) => state.reducerUser.user)

    // State Form
    const [nameSupermarche, setNameSupermarche] = useState(props.data.row.name)
    const [emailSupermarche, setEmailSupermarche] = useState(props.data.row.email)
    const [telephoneSupermarche, setTelephoneSupermarche] = useState(props.data.row.phone)
    const [geolocalisationSupermarche, setGeolocalisationSupermarche] = useState(props.data.row.geolocalisationName)
    const [longitudeSupermarche, setLongitudeSupermarche] = useState(props.data.row.longitude)
    const [latitudeSupermarche, setLatitudeSupermarche] = useState(props.data.row.latitude)
    const [descriptionSupermarche, setDescriptionSupermarche] = useState(props.data.row.description)
    const [livraisonSupermarche, setLivraisonSupermarche] = useState(props.data.row.livraison)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    //function "UpdateRestaurantHanlder"
    const supermarcheHandler = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        let data = {
            name: nameSupermarche,
            email: emailSupermarche,
            phone: telephoneSupermarche,
            geolocalisationName: geolocalisationSupermarche,
            longitude: longitudeSupermarche,
            latitude: latitudeSupermarche,
            description: descriptionSupermarche,
            livraison: livraisonSupermarche,
            updated_by: user.id
        }
        const id = props.data.row.id
        //send data to server
        await SupermarcheService.UpdateSupermarche(id,data)
        .then((response) => {
            handleClose()
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            handleClose()
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };

    return (
        <div>
            <IconButton aria-label="update" size="large" onClick={handleOpen}  color="warning">
                <AppRegistrationIcon fontSize="inherit" />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Modifier un supermarche
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={nameSupermarche} 
                            onChange={e => setNameSupermarche(e.target.value)}  
                            fullWidth 
                            label="Nom du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth"/>
                        <TextField 
                            value={emailSupermarche} 
                            onChange={e => setEmailSupermarche(e.target.value)}  
                            fullWidth 
                            label="Email du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={telephoneSupermarche} 
                            onChange={e => setTelephoneSupermarche(e.target.value)}  
                            fullWidth 
                            label="Telephone du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={livraisonSupermarche} 
                            onChange={e => setLivraisonSupermarche(e.target.value)}  
                            fullWidth 
                            label="Prix livraison du supermarche " 
                            type='number'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={geolocalisationSupermarche} 
                            onChange={e => setGeolocalisationSupermarche(e.target.value)}  
                            fullWidth 
                            label="Nom du lieu du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={longitudeSupermarche} 
                            onChange={e => setLongitudeSupermarche(e.target.value)}  
                            fullWidth 
                            label="Longitude du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={latitudeSupermarche} 
                            onChange={e => setLatitudeSupermarche(e.target.value)}  
                            fullWidth 
                            label="Latitude du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField
                            fullWidth
                            value={descriptionSupermarche}
                            onChange={e => setDescriptionSupermarche(e.target.value)} 
                            id="outlined-multiline-static"
                            label="Description du supermarche"
                            multiline
                            rows={4}
                            color="secondary"
                            sx={{ mt: 2 }}
                        />
                        <Button disabled={isLoading} onClick={supermarcheHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>

                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


import React, {useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Modal,Typography, TextField } from "@mui/material"; 
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

import { useSelector } from 'react-redux'
import PermissionService from '../../../services/PermissionService';



export default function ModalUpdate(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const user = useSelector((state) => state.reducerUser.user)

    // State Form
    const [libellePermission, setLibellePermission] = useState(props.data.row.libelle)
    const [slugPermission, setSlugPermission] = useState(props.data.row.slug)
    const [descriptionPermission, setDescriptionPermission] = useState(props.data.row.description)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const permissionHandler = async (e) => {
        e.preventDefault();

        let data = {
            libelle: libellePermission,
            slug: slugPermission,
            description: descriptionPermission,
            updated_by: user.id
        }
        const id = props.data.row.id
        //send data to server
        await PermissionService.UpdatePermission(id,data)
        .then((response) => {
            handleClose()
            setLibellePermission('')
            setSlugPermission('')
            setDescriptionPermission('')

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            handleClose()
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props.reload(false)
        })
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="warning" endIcon={<EditIcon />}>
                Modifier
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Modifier une boisson
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                    <TextField 
                            value={libellePermission} 
                            onChange={e => setLibellePermission(e.target.value)}  
                            fullWidth 
                            label="Libelle de la permission" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                         <TextField 
                            value={slugPermission} 
                            onChange={e => setSlugPermission(e.target.value)}  
                            fullWidth 
                            label="Slug de la permission" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField
                            fullWidth
                            value={descriptionPermission}
                            onChange={e => setDescriptionPermission(e.target.value)} 
                            id="outlined-multiline-static"
                            label="Description de la permission"
                            multiline
                            rows={4}
                            color="secondary"
                            sx={{ mt: 2 }}
                        />
                        <Button onClick={permissionHandler} variant="contained" sx={{float: "right", mt:2}}>Validez ici</Button>
                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


import api from "../http/http-common";

const RegisterSupplement = (data) => {
    return api.post('/supplement', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllSupplement = () => {
    return api.get('/supplement');
}

const DeleteSupplement = (id, data) => {
    return api.delete('/supplement/'+id, data);
}

const UpdateSupplement = (id, data) => {
    return api.put('/supplement/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const ComplementService ={
    RegisterSupplement,
    GetAllSupplement,
    DeleteSupplement,
    UpdateSupplement
}

export default ComplementService;


import { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import ModalRegister from "./component/ModalRegister";
import ModalUpdate from "./component/ModalUpdate";
import ModalDelete from "./component/ModalDelete";
import { toast } from 'react-toastify';

import PermissionService from '../../services/PermissionService';

const PermissionPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const reload = (res) => {
    if(res === true){
      permissionHandler()
    }
  }
  const columns = [
    {
      field: "libelle",
      headerName: "Libelle Permission",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "slug",
      headerName: "Slug Permission",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Action(s)",
      align: "right",
      headerAlign: "right",
      // headerName: "Action(s)",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <ModalUpdate data={cellValues} reload={reload}/>
            <ModalDelete data={cellValues} reload={reload} />
          </div>
          
        );
      }
    },
  ];
  const [dataPermission, setDataPermission] = useState([])
  const permissionHandler = async () => {
    //send data to server
    await PermissionService.GetAllPermission()
    .then((response) => {
      setDataPermission(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  useEffect(() => {
    //Runs on every render
    permissionHandler()
  }, []);
  return (
    <Box m="20px">
      <Header
        title="PERMISSIONS"
        subtitle="Liste de toutes les permissions"
      />
      <ModalRegister reload={reload} />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dataPermission}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default PermissionPage;

import React, {useState} from 'react';
import { Box, Button, Modal,Typography, TextField, IconButton } from "@mui/material"; 
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

import { useSelector } from 'react-redux'

import CodePromoService from '../../../services/CodePromoService';

export default function ModalUpdate(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);


    const user = useSelector((state) => state.reducerUser.user)

    // State Form

    const [sommeMin, setSommeMin] = useState(props.data.row.sommeMin)
    const [pourcentage, setPourcentage] = useState(props.data.row.pourcentage)
    const [startDate, setStartDate] = useState(props.data.row.startDate)
    const [endDate, setEndDate] = useState(props.data.row.endDate)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    //function "UpdatePlatHanlder"
    const codePromoHandler = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        let data = {
            sommeMin: sommeMin,
            pourcentage: pourcentage,
            startDate: startDate,
            endDate: endDate,
            updated_by: user.id
        }
        const id = props.data.row.id
        //send data to server
        await CodePromoService.UpdateCodePromo(id,data)
        .then((response) => {
            handleClose()

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            handleClose()

            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };

    return (
        <div>
            <IconButton aria-label="update" size="large" onClick={handleOpen}  color="warning">
                <AppRegistrationIcon fontSize="inherit" />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Modifier un code promo
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={sommeMin} 
                            onChange={e => setSommeMin(e.target.value)}  
                            fullWidth 
                            label="Somme Minimum " 
                            type='number'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={pourcentage} 
                            onChange={e => setPourcentage(e.target.value)}  
                            fullWidth 
                            label="Pourcentage Promo" 
                            type='number'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={startDate} 
                            onChange={e => setStartDate(e.target.value)}  
                            fullWidth 
                            focused
                            label="Date Debut Promo" 
                            type='date'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={endDate} 
                            onChange={e => setEndDate(e.target.value)}  
                            fullWidth 
                            focused
                            label="Date Fin Promo" 
                            type='date'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <Button disabled={isLoading} onClick={codePromoHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


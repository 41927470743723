import React from 'react'

import PrivateRoute from './PrivateRoute';

import { Routes, Route } from "react-router-dom";
import Dashboard from "../scenes/dashboard";
// import Team from "../scenes/team";
// import Invoices from "../scenes/invoices";
// import Contacts from "../scenes/contacts";
// import Form from "../scenes/form";
// import FAQ from "../scenes/faq";
// import Calendar from "../scenes/calendar/calendar";
import Login from '../scenes/login';
import BoissonPage from '../pages/boisson';
import CartePage from '../pages/carte';
import ComplementPage from '../pages/complement';
import PlatPage from '../pages/plat';
import RestaurantPage from '../pages/restaurant';
import RolePage from '../pages/role';
import UserPage from '../pages/users';
import PermissionPage from '../pages/permission';
import GestionUserPage from '../pages/gestionUser';
import { useSelector } from 'react-redux'
import SupplementPage from '../pages/supplement';
import VillePage from '../pages/ville';
import SupermarchePage from '../pages/supermarche';
import ProduitSupermarchePage from '../pages/produitSupermarche';
import CodePromoPage from '../pages/codePromo';


export default function AllRoute() {
    const token = useSelector((state) => state.reducerUser.token)
    return (
        <Routes>
            <Route path="*" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <Dashboard />
                    </PrivateRoute>
                } 
            />
            <Route path="/" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <Dashboard />
                    </PrivateRoute>
                } 
            />
            <Route path="/restaurants" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <RestaurantPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/supermarches" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <SupermarchePage />
                    </PrivateRoute>
                } 
            />
            <Route path="/produitSupermarche" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <ProduitSupermarchePage />
                    </PrivateRoute>
                } 
            />
            <Route path="/carte/:id/:mode" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <CartePage />
                    </PrivateRoute>
                } 
            />
            <Route path="/plats" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <PlatPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/complements" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <ComplementPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/supplements" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <SupplementPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/boissons" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <BoissonPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/codePromo" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <CodePromoPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/roles" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <RolePage />
                    </PrivateRoute>
                } 
            />
            <Route path="/permissions" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <PermissionPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/users" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <UserPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/gestionUsers" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <GestionUserPage />
                    </PrivateRoute>
                } 
            />
            <Route path="/ville" element=
                {
                    <PrivateRoute isLoggedIn={token}>
                        <VillePage />
                    </PrivateRoute>
                } 
            />
            <Route path="/login" element={<Login />} />
        </Routes>
    )
}

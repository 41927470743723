import React, {useState} from 'react';
import { Box, Button, Modal,Typography, Autocomplete, Checkbox, TextField  } from "@mui/material"; 
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux'

import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import ManagementUserService from '../../../../services/ManagementUserService';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function ModalValidate(props) {

    const [open, setOpen] = useState(false);
    const [option, setOption] = useState([]);

    const handleOpen = () => {
        if(props.userChoose !== null){
            setOpen(true)
        }else{
            toast.error('Veillez selectionner un utilisateur', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    const handleClose = () => setOpen(false);

    const user = useSelector((state) => state.reducerUser.user)

    const managementUserHandler = async (e) => {
        e.preventDefault();
        let data = []
        option.forEach(element => {
            data.push({
                roleId: element.id,
                userId: props.userChoose.id,
                created_by: user.id,
                mode: 'role'
            })
        });

        //send data to server
        await ManagementUserService.ManagementUser(data)
        .then((response) => {
            handleClose()
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            handleClose()
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props.reload(false)
        })
    };

    
    return (
        <div>
            <Button sx={{marginLeft:2}} onClick={handleOpen} variant="contained" color="secondary" endIcon={<DoneAllIcon />}>
                Validez ici
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Ajouter / Modifier Role(s)
                    </Typography>
                    <div id="modal-modal-description" style={{marginTop: "2%"}}>
                        <Autocomplete
                            color="secondary"
                            onChange={(event, value) => setOption(value)}
                            multiple
                            id="checkboxes-tags-demo"
                            options={props.data}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.libelle}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.libelle}
                                </li>
                            )}
                            style={{ width: '100%', color: 'white' }}
                            renderInput={(params) => (
                                <TextField  {...params} label="Selectionnez le/les role(s)" placeholder="Tous les roles" color='secondary' />
                            )}
                        />
                        <br/>
                        <Typography>
                            Etes-vous sur de vouloir sauvegarder les modifications ou les ajouts
                        </Typography>

                        <Button color="error" onClick={managementUserHandler} variant="contained" sx={{float: "right", mt:2}}>Oui, Sauvegardez</Button>
                        <Button color="info" onClick={handleClose} variant="contained" sx={{float: "right", mt:2, marginRight:2}}>Non, Annulez</Button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </div>
        
    )
    
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


import { useState } from "react";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import { useSelector } from 'react-redux'
import AllRoute from "./route/AllRoute"

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const token = useSelector((state) => state.reducerUser.token)

  if ( (token !== null) && (token !== false)  ) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <AllRoute />
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }else{
    return (
      <AllRoute />
    )
  }
}

export default App;

import api from "../http/http-common";

const RegisterComplement = (data) => {
    return api.post('/complement', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllComplement = () => {
    return api.get('/complement');
}

const DeleteComplement = (id, data) => {
    return api.delete('/complement/'+id, data);
}

const UpdateComplement = (id, data) => {
    return api.put('/complement/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const ComplementService ={
    RegisterComplement,
    GetAllComplement,
    DeleteComplement,
    UpdateComplement
}

export default ComplementService;


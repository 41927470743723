import axios from 'axios';
import AuthService from '../services/AuthService';
// Obtenez l'URL de base en fonction de l'environnement
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_LOCAL : process.env.REACT_APP_API_URL_PROD;

// Configurez axios avec l'URL de base
const api = axios.create({
  baseURL,
  // Autres configurations d'axios
  headers: {
    "content-type": "application/json",
  }
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


// Intercepteur pour gérer le rafraîchissement du token si nécessaire
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Si l'erreur est due à un token expiré et que la requête n'est pas déjà en cours de rafraîchissement
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const existingToken = sessionStorage.getItem('token');
      if (existingToken && existingToken !== 'null') {
        try {
          // Effectuez le rafraîchissement du token
          const refreshedToken = await refreshAccessToken();
  
          // Mettez à jour le header d'Authorization avec le nouveau token
          originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
  
          // Réexécutez la requête d'origine avec le nouveau token
          return api(originalRequest);
        } catch (refreshError) {
          sessionStorage.clear()
          window.location.href = '/login'
          return Promise.reject(refreshError);
        }
      }else {
        // 'bp_token' doesn't exist or is null, redirect to login page or handle it accordingly
        console.warn("'bp_token' not found or is null. Redirecting to login page.");
        // redirect to login page or handle it accordingly
        return Promise.reject(error);
      }
      
    }

    return Promise.reject(error);
  }
);

// Fonction pour rafraîchir le token d'accès
async function refreshAccessToken() {
  try {
    const refreshToken = sessionStorage.getItem('refreshToken');

    // Effectuez la requête pour rafraîchir le token
    const response = await AuthService.refresh({ refresh_token: refreshToken });

    // Mettez à jour le token dans la session
    sessionStorage.setItem('token', response.data.accessToken);

    return response.data.refreshToken;
  } catch (refreshError) {
    // Si le rafraîchissement échoue, vous pouvez rediriger vers la page de connexion ou effectuer d'autres actions nécessaires
    // replaceWithLoginPage();
    // ou
    // handleRefreshError(refreshError);
    throw refreshError;
  }
}

export default api
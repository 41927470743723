import api from "../http/http-common";

const RegisterProduitSupermarche = (data) => {
    return api.post('/produitSupermarche', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllProduitSupermarche = () => {
    return api.get('/produitSupermarche');
}

const DeleteProduitSupermarche = (id, data) => {
    return api.delete('/produitSupermarche/'+id, data);
}

const UpdateProduitSupermarche = (id, data) => {
    return api.put('/produitSupermarche/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const ProduitSupermarcheService ={
    RegisterProduitSupermarche,
    GetAllProduitSupermarche,
    DeleteProduitSupermarche,
    UpdateProduitSupermarche
}

export default ProduitSupermarcheService;


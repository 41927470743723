import React, {useState} from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Autocomplete, Button, Modal,Typography, TextField } from "@mui/material"; 
import SupermarcheService from '../../../services/SupermarcheService';

import { useSelector } from 'react-redux'

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



export default function ModalRegister(props) {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const user = useSelector((state) => state.reducerUser.user)


    // State Form
    const [nameSupermarche, setNameSupermarche] = useState('')
    const [emailSupermarche, setEmailSupermarche] = useState('')
    const [telephoneSupermarche, setTelephoneSupermarche] = useState('')
    const [geolocalisationSupermarche, setGeolocalisationSupermarche] = useState('')
    const [longitudeSupermarche, setLongitudeSupermarche] = useState('')
    const [latitudeSupermarche, setLatitudeSupermarche] = useState('')
    const [descriptionSupermarche, setDescriptionSupermarche] = useState('')
    const [imageGardeSupermarche, setImageGardeSupermarche] = useState('')
    const [livraisonSupermarche, setLivraisonSupermarche] = useState('')
    const [villeSupermarche, setVilleSupermarche] = useState({})


    //function "RegisterRestaurantHanlder"
    const supermarcheHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let data = {
            name: nameSupermarche,
            email: emailSupermarche,
            phone: telephoneSupermarche,
            geolocalisationName: geolocalisationSupermarche,
            longitude: longitudeSupermarche,
            latitude: latitudeSupermarche,
            description: descriptionSupermarche,
            photoGarde: imageGardeSupermarche,
            villeId: villeSupermarche.id,
            livraison: livraisonSupermarche,
            created_by: user.id
        }

        //send data to server
        await SupermarcheService.RegisterSupermarche(data)
        .then((response) => {
            handleClose()
            setNameSupermarche('')
            setEmailSupermarche('')
            setTelephoneSupermarche('')
            setGeolocalisationSupermarche('')
            setLongitudeSupermarche('')
            setLatitudeSupermarche('')
            setDescriptionSupermarche('')
            setVilleSupermarche('')
            setLivraisonSupermarche('')
            setImageGardeSupermarche()

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)

            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="secondary" endIcon={<SaveAltIcon />}>
                Ajouter un Supermarche
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Ajouter un nouveau supermarche
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={nameSupermarche} 
                            onChange={e => setNameSupermarche(e.target.value)}  
                            fullWidth 
                            label="Nom du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={emailSupermarche} 
                            onChange={e => setEmailSupermarche(e.target.value)}  
                            fullWidth 
                            label="Email du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={telephoneSupermarche} 
                            onChange={e => setTelephoneSupermarche(e.target.value)}  
                            fullWidth 
                            label="Telephone du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={livraisonSupermarche} 
                            onChange={e => setLivraisonSupermarche(e.target.value)}  
                            fullWidth 
                            label="Prix livraison du supermarche " 
                            type='number'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={geolocalisationSupermarche} 
                            onChange={e => setGeolocalisationSupermarche(e.target.value)}  
                            fullWidth 
                            label="Nom du lieu du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={longitudeSupermarche} 
                            onChange={e => setLongitudeSupermarche(e.target.value)}  
                            fullWidth 
                            label="Longitude du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={latitudeSupermarche} 
                            onChange={e => setLatitudeSupermarche(e.target.value)}  
                            fullWidth 
                            label="Latitude du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            type='file'
                            focused
                            onChange={(e) => setImageGardeSupermarche(e.target.files[0])}  
                            fullWidth 
                            label="Photo de garde du supermarche" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <Autocomplete
                            fullWidth
                            disablePortal
                            sx={{ mt: 2 }} 
                            onChange={(event, newValue) => {
                                setVilleSupermarche(newValue);
                            }}
                            color="secondary"
                            id="outlined-adornment-code_mission-login"
                            options={props.dataVille}
                            getOptionLabel={(option) => option.libelle}
                            renderInput={(params) => <TextField color="secondary" {...params} label="Nom de la ville" />}
                        />
                        <TextField
                            fullWidth
                            value={descriptionSupermarche}
                            onChange={e => setDescriptionSupermarche(e.target.value)} 
                            id="outlined-multiline-static"
                            label="Description du supermarche"
                            multiline
                            rows={4}
                            color="secondary"
                            sx={{ mt: 2 }}
                        />
                        <Button disabled={isLoading} onClick={supermarcheHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


import React, {useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Modal,Typography, IconButton } from "@mui/material"; 

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

import { useSelector } from 'react-redux'

import PlatService from '../../../services/PlatService';



export default function ModalDelete(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);

    const user = useSelector((state) => state.reducerUser.user)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    //function "DeletePlatHanlder"
    const platHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let data = {
            deleted_by: user.id
        }
        const id = props.data.row.id

        //send data to server
        await PlatService.DeletePlat(id,data)
        .then((response) => {
            handleClose()
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            handleClose()
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };

    return (
        <div>
            <IconButton aria-label="delete" size="large"  onClick={handleOpen} color="error">
                <DeleteIcon fontSize="inherit" />
            </IconButton>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Supprimer un plat
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <Typography>
                            Etes-vous sur de vouloir supprimer cet element
                        </Typography>

                        
                        <Button disabled={isLoading} onClick={platHandler} color="error" variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Oui, Supprimez"}
                        </Button>
                        <Button color="info" onClick={handleClose} variant="contained" sx={{float: "right", mt:2, marginRight:2}}>Non, Annulez</Button>
                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


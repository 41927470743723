import React from 'react'
import { 
    ListItem,ListItemAvatar,
  Avatar,
  ListItemText 
} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import ModalDeleteList from '../ComponentModalDelete';
import ModalChangePrice from '../componentChangePrice';

const ComponentListSupplement = (props) => {
    const reloadIntermediare = (res) => {
        if(res === true){
            props.reload(true)
        }
    }
    if((props.value.supplement)){
        return (
        
            <ListItem
                key={props.index+1}
                secondaryAction={
                    <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
                        <ModalChangePrice mode="RESTAURANT" data={props.value}   reload={reloadIntermediare}/>
                        <ModalDeleteList  data={props.value}  reload={reloadIntermediare} />
                    </div>
                }
            >
                <ListItemAvatar>
                    <Avatar>
                        <FolderIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.value.supplement.name+ ': Prix - '+props.value.price+' Frs CFA'}
                />
            </ListItem>
        )
    }
    
}

export default ComponentListSupplement;
import api from "../http/http-common";

const RegisterRestaurant = (data) => {
    return api.post('/restaurant', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

const GetAllRestaurant = () => {
    return api.get('/restaurant');
}

const DeleteRestaurant = (id, data) => {
    return api.delete('/restaurant/'+id, data);
}

const UpdateRestaurant = (id, data) => {
    return api.put('/restaurant/'+id, data, { headers: {'Content-Type': 'multipart/form-data'} });
}

const RestaurantService ={
    RegisterRestaurant,
    GetAllRestaurant,
    DeleteRestaurant,
    UpdateRestaurant
}

export default RestaurantService;


import api from "../http/http-common";

const RegisterVille = (data) => {
    return api.post('/ville', data)
}

const GetAllVille = () => {
    return api.get('/ville');
}

const DeleteVille = (id, data) => {
    return api.delete('/ville/'+id, data);
}

const UpdateVille = (id, data) => {
    return api.put('/ville/'+id, data);
}

const VilleService ={
    RegisterVille,
    GetAllVille,
    DeleteVille,
    UpdateVille
}

export default VilleService;


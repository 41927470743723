import api from "../http/http-common";

const RegisterCarte = (data) => {
    return api.post('/carte', data)
}

const GetAllCarte = (id, mode) => {
    return api.get('/carte/'+id+'/'+mode);
}

const DeleteCarte = (id, data) => {
    return api.delete('/carte/'+id, data);
}

const UpdateCarte = (id,mode, data) => {
    return api.put('/carte/'+id+'/'+mode, data);
}

const ComplementService ={
    RegisterCarte,
    GetAllCarte,
    DeleteCarte,
    UpdateCarte
}

export default ComplementService;


import api from "../http/http-common";

const RegisterPermission = (data) => {
    return api.post('/permissions', data)
}

const GetAllPermission = () => {
    return api.get('/permissions');
}

const DeletePermission = (id, data) => {
    return api.delete('/permissions/'+id, data);
}

const UpdatePermission = (id, data) => {
    return api.put('/permissions/'+id, data);
}

const PermissionService ={
    RegisterPermission,
    GetAllPermission,
    DeletePermission,
    UpdatePermission
}

export default PermissionService;
import React, {useState} from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Autocomplete, Button, Modal,Typography, TextField } from "@mui/material"; 
import RestaurantService from '../../../services/RestaurantService';

import { useSelector } from 'react-redux'

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



export default function ModalRegister(props) {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const user = useSelector((state) => state.reducerUser.user)


    // State Form
    const [nameRestaurant, setNameRestaurant] = useState('')
    const [emailRestaurant, setEmailRestaurant] = useState('')
    const [telephoneRestaurant, setTelephoneRestaurant] = useState('')
    const [geolocalisationRestaurant, setGeolocalisationRestaurant] = useState('')
    const [longitudeRestaurant, setLongitudeRestaurant] = useState('')
    const [latitudeRestaurant, setLatitudeRestaurant] = useState('')
    const [descriptionRestaurant, setDescriptionRestaurant] = useState('')
    const [imageGardeRestaurant, setImageGardeRestaurant] = useState('')
    const [livraisonRestaurant, setLivraisonRestaurant] = useState('')
    const [villeRestaurant, setVilleRestaurant] = useState({})


    //function "RegisterRestaurantHanlder"
    const restaurantHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let data = {
            name: nameRestaurant,
            email: emailRestaurant,
            phone: telephoneRestaurant,
            geolocalisationName: geolocalisationRestaurant,
            longitude: longitudeRestaurant,
            latitude: latitudeRestaurant,
            description: descriptionRestaurant,
            photoGarde: imageGardeRestaurant,
            villeId: villeRestaurant.id,
            livraison: livraisonRestaurant,
            created_by: user.id
        }

        //send data to server
        await RestaurantService.RegisterRestaurant(data)
        .then((response) => {
            handleClose()
            setNameRestaurant('')
            setEmailRestaurant('')
            setTelephoneRestaurant('')
            setGeolocalisationRestaurant('')
            setLongitudeRestaurant('')
            setLatitudeRestaurant('')
            setDescriptionRestaurant('')
            setVilleRestaurant('')
            setLivraisonRestaurant('')
            setImageGardeRestaurant()

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)

            props.reload(true)
        })
        .catch((error) => {
            //assign error to state "validation"
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false)
            props.reload(false)
        })
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="secondary" endIcon={<SaveAltIcon />}>
                Ajouter un restaurant
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                    Ajouter un nouveau restaurant
                    </Typography>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField 
                            value={nameRestaurant} 
                            onChange={e => setNameRestaurant(e.target.value)}  
                            fullWidth 
                            label="Nom du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={emailRestaurant} 
                            onChange={e => setEmailRestaurant(e.target.value)}  
                            fullWidth 
                            label="Email du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={telephoneRestaurant} 
                            onChange={e => setTelephoneRestaurant(e.target.value)}  
                            fullWidth 
                            label="Telephone du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />

                        <TextField 
                            value={livraisonRestaurant} 
                            onChange={e => setLivraisonRestaurant(e.target.value)}  
                            fullWidth 
                            label="Prix livraison du restaurant" 
                            type='number'
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <TextField 
                            value={geolocalisationRestaurant} 
                            onChange={e => setGeolocalisationRestaurant(e.target.value)}  
                            fullWidth 
                            label="Nom du lieu du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={longitudeRestaurant} 
                            onChange={e => setLongitudeRestaurant(e.target.value)}  
                            fullWidth 
                            label="Longitude du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            value={latitudeRestaurant} 
                            onChange={e => setLatitudeRestaurant(e.target.value)}  
                            fullWidth 
                            label="Latitude du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" />
                        <TextField 
                            type='file'
                            focused
                            onChange={(e) => setImageGardeRestaurant(e.target.files[0])}  
                            fullWidth 
                            label="Photo de garde du restaurant" 
                            sx={{ mt: 2 }} 
                            color="secondary"
                            id="fullWidth" 
                        />
                        <Autocomplete
                            fullWidth
                            disablePortal
                            sx={{ mt: 2 }} 
                            onChange={(event, newValue) => {
                                setVilleRestaurant(newValue);
                            }}
                            color="secondary"
                            id="outlined-adornment-code_mission-login"
                            options={props.dataVille}
                            getOptionLabel={(option) => option.libelle}
                            renderInput={(params) => <TextField color="secondary" {...params} label="Nom de la ville" />}
                        />
                        <TextField
                            fullWidth
                            value={descriptionRestaurant}
                            onChange={e => setDescriptionRestaurant(e.target.value)} 
                            id="outlined-multiline-static"
                            label="Description du Restaurant"
                            multiline
                            rows={4}
                            color="secondary"
                            sx={{ mt: 2 }}
                        />
                        <Button disabled={isLoading} onClick={restaurantHandler} variant="contained" sx={{float: "right", mt:2}}>
                            {/* Validez ici */}
                            {isLoading ? "En cous ...": "Validez ici"}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
        
    )
}


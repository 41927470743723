import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography,
  List,Grid,
  } from "@mui/material";
import { toast } from 'react-toastify';
import Header from "../../../components/Header";
import { useParams } from "react-router-dom";

import ComponentListProduitSupermarche from '../component/ComponentListProduitSupermarche';

import ProduitSupermarcheService from '../../../services/ProduitSupermarcheService';
import CarteService from '../../../services/CarteService';
import CarteSupermarcheModal from '../component/CarteSupermarcheModal';

const SectionSupermarche = () => {
  const routeParams = useParams();
  const reload = (res) => {
    if(res === true){
      carteHandler()
    }
  }

  const [data, setData] = useState([])
  const carteHandler = async () => {
    let mode = 'SUPERMARCHE'
    //send data to server
    await CarteService.GetAllCarte(routeParams.id, mode)
    .then((response) => {
      setData(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const [dataProduitSupermarche, setDataProduitSupermarche] = useState([])
  const produitSupermarcheHandler = async () => {
    //send data to server
    await ProduitSupermarcheService.GetAllProduitSupermarche()
    .then((response) => {
      setDataProduitSupermarche(response.data.data)
    })
    .catch((error) => {
      //assign error to state "validation"
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  

  useEffect(() => {
    carteHandler()
    produitSupermarcheHandler()
  }, []);
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  return (
    <Box  m="20px">
        <Header 
          title="CARTE SUPERMARCHE"
          subtitle="Liste de tous les produits du supermarche"
        />
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
            <CarteSupermarcheModal dataProduitSupermarche={dataProduitSupermarche}  supermarcheId={routeParams.id} reload={reload} />
        </div>
        <Grid item xs={12} md={6}>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h2" component="div">
                Tous les produits de : <span>{(data.length > 0)? data[0].supermarche.name: ''}</span>
            </Typography>
            <Demo>
                <List >
                    {data.map((value,i) =>
                      <ComponentListProduitSupermarche value={value}/>
                    )}
                </List>
            </Demo>
        </Grid>
    </Box>
  );
};

export default SectionSupermarche;
